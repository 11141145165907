import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Page from "components/Page";
import Wrapper from "components/Wrapper";
import { PageLocale } from "types";
import Masonry from "react-masonry-component";
import Card from "components/Card";

import {
  HeroImg,
  Separator,
  HeroTitle,
  HeroSubtitle,
  Hero,
  HeroMessageContainer,
  Section,
  HeroImageContainer
} from "components/UI";
interface Props {
  data: {
    page: {
      fields: {
        name: string;
        locales: {
          language: string;
          path: string;
          title: string;
          seo: {
            description: string;
            image: string;
          };
          hero: {
            title: string;
            subtitle: string;
            image: string;
          };
          servicesList: {
            title: string;
            image: string;
            description: string;
          }[];
        }[];
      };
    };
  };
  pageContext: {
    name: string;
    locale: string;
  };
}

const ServicesPage = ({ data, pageContext }: Props) => {
  const masonryOptions = {
    transitionDuration: 0
  };
  const currentPage = data.page.fields.locales.find(
    locale => locale.language === pageContext.locale
  )!;

  return (
    <Page
      title={currentPage.title}
      description={currentPage.seo.description}
      localeCode={pageContext.locale}
      image={currentPage.seo.image}
      pageLocales={data.page.fields.locales.map(
        (locale: any): PageLocale => ({
          code: locale.language,
          url: locale.path
        })
      )}
    >
      <Hero secondary>
        <HeroImageContainer>
          <HeroImg src={currentPage.hero.image} />
        </HeroImageContainer>
        <Wrapper>
          <HeroMessageContainer>
            <HeroTitle
              data-aos="fade-right"
              dangerouslySetInnerHTML={{ __html: currentPage.hero.title }}
            />
            <Separator light />
            <HeroSubtitle data-aos="fade-right" data-aos-delay="300">
              {currentPage.hero.subtitle}
            </HeroSubtitle>
          </HeroMessageContainer>
        </Wrapper>
      </Hero>
      <ServicesSection>
        <ServicesWrapper>
          <Masonry elementType={"ul"} options={masonryOptions}>
            {currentPage.servicesList.map(item => (
              <ServiceCard
                key={item.title}
                title={item.title}
                image={item.image}
                description={item.description}
              />
            ))}
          </Masonry>
        </ServicesWrapper>
      </ServicesSection>
    </Page>
  );
};

const ServicesWrapper = styled(Wrapper)`
  @media (max-width: 1260px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding: 20px;
  }
`;

const ServicesSection = styled(Section)`
  padding-top: 40px;
`;

const ServiceCard = styled(Card)`
  margin: 20px;
  width: calc(50% - 40px);
  @media (max-width: 650px) {
    width: 100%;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export const query = graphql`
  query ServicesPageQuery($name: String!) {
    page: pagesJson(fields: { name: { eq: $name } }) {
      fields {
        name
        locales {
          language
          path
          title
          seo {
            description
            image
          }
          hero {
            title
            subtitle
            image
          }
          servicesList {
            title
            image
            description
          }
        }
      }
    }
  }
`;

export default ServicesPage;
